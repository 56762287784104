* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

.background-orange {
  background-color: #F97316;
}

.background-brown {
  background: linear-gradient(0deg, rgba(54, 31, 12, 1) 0%, rgba(130, 101, 78, 1) 75%);
}

.background-beige {
  background: linear-gradient(0deg, rgba(117, 79, 7, 1) 0%, rgba(213, 171, 92, 1) 75%);
}

.background-blue {
  background: linear-gradient(0deg, rgba(2, 21, 51, 1) 0%, rgba(5, 75, 185, 1) 75%);
}

.background-gray {
  background: linear-gradient(0deg, rgba(115, 116, 117, 1) 0%, rgba(255, 255, 255, 1) 75%);
}

.bg-yellow {
  background-color: #FDB721;
}
.bg-brown {
  background-color: #6A4D1B;
}

.text-orange {
  color: #F97316;
}

@media (min-width: 1024px) {
  .clip-path-23 {
    clip-path: circle(23% at 50% 50%);
  }


  .fooder .cover {
    background-image: url(./Assets/textCover/wordFooder.png);
  }
  .boolflix .cover {
    background-image: url(./Assets/textCover/wordBoolflix.png);
  }
  .gamecreator .cover {
    background-image: url(./Assets/textCover/wordGamecreator.png);
  }
  
}
@media (max-width: 1023px) {
  .fooder .cover-tablet {
    background-image: url(./Assets/textCover/wordFooderTablet.png);
    background-color:white;
  }
  .boolflix .cover-tablet {
    background-image: url(./Assets/textCover/wordBoolflixTablet.png);
    background-color:white;
  }
  .gamecreator .cover-tablet {
    background-image: url(./Assets/textCover/wordGamecreatorTablet.png);
    background-color:black;
  }
}
@media (max-width: 640px) {
  .fooder .cover-mobile {
    background-image: url(./Assets/textCover/wordFooderMobile.png);
    background-color:white;
  }
  .boolflix .cover-mobile {
    background-image: url(./Assets/textCover/wordBoolflixMobile.png);
    background-color:white;
  }
  .gamecreator .cover-mobile {
    background-image: url(./Assets/textCover/wordGamecreatorMobile.png);
    background-color:black;
  }
}

section {
  position: relative;
  width: 100%;
  height: 100vh;
}




.transition .cover {
  transition: transform 1s ease, opacity 1s ease;
}
.transition-mobile .cover {
  transition: transform 1s ease, opacity 1s ease;
}

.animate .cover {
  transform: scale(10);
  opacity: 0;
}
.animate-mobile .cover {
  transform: scale(10);
  opacity: 0;
}

.transition .images-transition {
  transition: width 1s ease;
}

.images-transition {
  width: 100%;
}

.transition-mobile .text-animation {
  transition: top 1s ease;
}

.animate-mobile .text-animation {
  top: 50%;
}

.transition .text-animation {
  transition: left 1s ease;
}

.animate .text-animation {
  left: 50%;
}

.animate-completed .cover {
  display: none;
}